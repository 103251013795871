// NOCA:InnerDomainLeak(内部域名过滤)
/* eslint-disable operator-linebreak */
/* eslint-disable no-plusplus */
enum OneTrustMap {
  /** 必要的 */
  'Necessary' = 'C0001',
  /** 性能分析 */
  'Performance' = 'C0002',
  /** 功能性的 */
  'Functional' = 'C0003',
  /** 广告推荐偏好类的 */
  'Advertising' = 'C0004',
  /** 社交媒体类的 */
  'SocialMedia' = 'C0005',
}

class CheckHandler {
  isCookiePrevent = () => {
    if (window?.OneTrust && !window?.OnetrustActiveGroups?.includes(OneTrustMap.Functional)) {
      // 阻止
      return true;
    }
    return false;
  };
}

const OneTrustConfig = {
  pre: {
    script1: {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/consent/5c6efc75-7033-4dee-bdff-5591f8348cef-test/OtAutoBlock.js',
    },
    script2: {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': '5c6efc75-7033-4dee-bdff-5591f8348cef-test',
    },
  },
  prod: {
    script1: {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/consent/6490fd16-409e-4db1-83f6-2398102bd949/OtAutoBlock.js',
    },
    script2: {
      type: 'text/javascript',
      src: 'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js',
      'data-document-language': 'true',
      charset: 'UTF-8',
      'data-domain-script': '6490fd16-409e-4db1-83f6-2398102bd949',
    },
  },
};

// 爬虫js
export function checkUserAgent() {
  const { userAgent } = navigator;
  const pattern =
    /prerender|googlebot|bingbot|yandex|baiduspider|twitterbot|facebookexternalhit|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest\/0\.|pinterestbot|slackbot|vkShare|W3C_Validator|whatsapp/i;
  return !pattern.test(userAgent);
}

export const initOneTrust = (): Promise<any> => {
  // 在谷歌等爬虫下不进入页面
  const isPassAgent = checkUserAgent();
  if (!isPassAgent || isCarbon() || navigator?.webdriver) {
    return Promise.resolve('no pass agent');
  }
  if (!['pre', 'prod'].find((v) => v === process.env.DEPLOY_ENV)) {
    return Promise.resolve('no pre or prod env');
  }
  return new Promise((resolve: (res: any) => void) => {
    if (!window.OneTrust) {
      try {
        let count = 0;
        const config = OneTrustConfig[process.env.DEPLOY_ENV];
        const scripts = Object.keys(config);
        scripts.forEach((v) => {
          const script = document.createElement('script');
          const keys = Object.keys(config[v]);
          keys.forEach((k) => {
            script.setAttribute(k, config[v][k]);
          });
          script.onload = () => {
            count++;
            if (count === scripts.length) {
              resolve('success');
            }
          };
          document.head.appendChild(script);
        });
      } catch (error) {
        resolve('error');
      }
    }
  });
};

export const OneTrustCheck = new CheckHandler();

export const isCarbon = () => {
  const carbonUrls = [
    'carbonxprogram.com',
    'www.carbonxprogram.com',
    'pre.carbonxprogram.com',
    'test.carbonxprogram.woa.com',
    'dev.carbonxprogram.woa.com',
  ];
  const { host } = location;
  return carbonUrls.includes(host);
};
