import 'antd-mobile/es/global';
// 导入样式
import '@tea/component/tea.css';
// 导入style
import './styles/app.less';
import 'tdesign-react/es/style/index.css';

import './utils/storage';
import './styles/tailwind.css';
import './styles/mail.less';
import './styles/reset-onetrust.less';
import './styles/tdesign-reset.less';
import './styles/map.less';
// 导入依赖
import { RouteItem, app } from '@tea/app';
import { I18NInstance, init } from '@tea/app/i18n';
// import { Overview } from './routes/overview';

// 引入await async 运行环境
import 'regenerator-runtime/runtime.js';
import { lazy } from 'react';
import { lg } from './utils/language';
import { isCarbon } from './utils/oneTrust';
const ILayout = lazy(() => import('./components/layout/Layout'));

function removeBasenameFromPathname(pathname = location.pathname, basename = '/zh') {
  const regex = new RegExp(`^${basename}`);
  return pathname.replace(regex, '');
}
// 路由代码
type IRoute = RouteItem & {
  title?: string;
  titleEn?: string;
};
const carbon = isCarbon();
// eslint-disable-next-line @typescript-eslint/naming-convention
const routes: IRoute[] = [
  !carbon && { path: '', component: lazy(() => import(/* webpackChunkName: "home" */ '@src/routes/Home')) },
  {
    path: '/', // 门户console/contribute-team
    component: lazy(() => import('@src/components/layout/ContentLayout')),
    children: [
      { path: '/programs', component: lazy(() => import('@src/routes/Resources-list')) },
      { path: '/carbon', component: lazy(() => import('@src/routes/Carbon')) },
      { path: '/programs-list', component: lazy(() => import('@src/routes/Resources-list')) },
      { path: '/programs/detail/:id', component: lazy(() => import('@src/routes/Resource-detail')) },
      { path: '/tools', component: lazy(() => import('@src/routes/Tools')) },
      { path: '/tools/detail/:id', component: lazy(() => import('@src/routes/Tools/ForPolicy')) },
      { path: '/about', component: lazy(() => import('@src/routes/About')) },
      { path: '/sitemap', component: lazy(() => import('@src/routes/Sitemap')) },
      { path: '/help', component: lazy(() => import('@src/routes/Helpcenter/HelpCenter')) },
      { path: '/creator', component: lazy(() => import('@src/routes/About/Creator/Creator')) },
      { path: '/email-ver', component: lazy(() => import('@src/routes/EmailVerify/VerifyIndex')) },
      // { path: '/solutionhub', component: lazy(() => import('@src/routes/Innovation/innovation-main')) },
      { path: '/solutions', component: lazy(() => import('@src/routes/Innovation/innovation-list')) },
      { path: '/innovators', component: lazy(() => import('@src/routes/Innovation/innovation-team/InnovationTeam')) },
      { path: '/knowledge', component: lazy(() => import('@src/routes/Innovation/innovation-list')) },
      {
        path: '/solutions/detail/:id',
        component: lazy(() => import('@src/routes/Innovation/innovation-detail')),
      },
      {
        path: '/knowledge/detail/:id',
        component: lazy(() => import('@src/routes/Innovation/innovation-info')),
      },
      { path: '/innovators/detail/:id', component: lazy(() => import('@src/routes/Firm-detail')) },
      { path: '/pdf2md', component: lazy(() => import('@src/routes/Pdf2md/Pdf2md')) },
    ],
  },
  {
    path: '/console', // 门户后台
    component: lazy(() => import('@src/components/layout/Console/ConsoleLayout')),
    children: [
      { path: 'account-indv', component: lazy(() => import('@src/routes/PersonInfo')) },
      { path: 'team-verify', component: lazy(() => import('@src/routes/TeamGroupAuth/TeamGroupAuth')) },
      { path: 'person', component: lazy(() => import('@src/routes/TeamPerson/TeamPerson')) },
      { path: 'inmail', component: lazy(() => import('@src/routes/Message/message-list')) },
      { path: 'inmail/detail/:id', component: lazy(() => import('@src/routes/Message/message-detail')) },
      { path: 'manage-subscription', component: lazy(() => import('@src/routes/Message/message-manage')) },
      { path: 'message-manage', component: lazy(() => import('@src/routes/Message/message-manage')) },
      { path: 'broadcast', component: lazy(() => import('@src/routes/Message/message-push')) },
      { path: 'broadcast/detail/:id', component: lazy(() => import('@src/routes/Message/message-push-detail')) },
      { path: 'favorites', component: lazy(() => import('@src/routes/Favorite')) },
      { path: 'programs', component: lazy(() => import('@src/routes/Resource-console/resource-list')) },
      // 查看详情
      {
        path: 'programs/view/:id',
        component: lazy(() => import('@src/routes/Resource-console/resource-detail')),
      },
      // 编辑
      {
        path: 'programs/publish/:id',
        component: lazy(() => import('@src/routes/Resource-console/resource-publish')),
      },
      // 报名管理
      { path: 'manage-applications', component: lazy(() => import('@src/routes/Apply-console/apply-list')) },
      // 报名详情
      {
        path: 'manage-applications/applied-edit',
        component: lazy(() => import('@src/routes/Apply-console/apply-edit')),
      },
      {
        path: 'manage-applications/applied-recycle',
        component: lazy(() => import('@src/routes/Apply-console/apply-recycle')),
      },
      { path: 'applied-programs', component: lazy(() => import('@src/routes/Apply-console/resourece-apply')) },
      { path: 'knowledge', component: lazy(() => import('@src/routes/InnovationCon/innovation-list')) },
      {
        path: 'contribute-team/detail/*',
        component: lazy(() => import('@src/routes/InnovationLibrary/TeamDetail/TeamDetailIndexCopy')),
      },
      { path: 'contribute-team', component: lazy(() => import('@src/routes/InnovationLibrary/InnovationIndex')) },
      { path: 'knowledge/detail/:id', component: lazy(() => import('@src/routes/InnovationCon/innovation-publish')) },
      { path: 'resource-publish', redirect: '/console/programs/publish' },
      { path: 'resource-detail', redirect: '/console/resource-list/resource-detail' },
      {
        path: 'team-indv/import',
        component: lazy(() => import('@src/routes/TeamIntro/TeamImport')),
      },
      {
        path: 'team-indv/*',
        component: lazy(() => import('@src/routes/TeamIntro/TeamIntro')),
      },
    ],
  },
  { path: '/login', component: lazy(() => import('@src/routes/Account/Login')) },
  { path: '/signup', component: lazy(() => import('@src/routes/Account/SignUp')) },
  { path: '/findpwd', component: lazy(() => import('@src/routes/Account/FindPwd')) },
  { path: '/pdfview', component: lazy(() => import('@src/routes/PdfView')) },
  { path: '/third-signup/wechat', component: lazy(() => import('@src/routes/Account/SignUp')) },
  { path: '/third-login/wechat', component: lazy(() => import('@src/routes/Account/Login')) },
  { path: '/third-login/google', component: lazy(() => import('@src/routes/Account/Third/Google')) },
  {
    path: '/third-login/makeable',
    component: lazy(() => import('@src/routes/LoginThird/components/Makeable/Makeable')),
  },
  { path: '/third-login', component: lazy(() => import('@src/routes/LoginThird/LoginThird')) },
  { path: '/oauth/authorize', component: lazy(() => import('@src/routes/ThirdAuthorize/ThirdAuthorize')) },
  { path: '/external-link', component: lazy(() => import('@src/routes/ExternalLink/ExternalLink')) },
  { path: '/logout', component: lazy(() => import('@src/routes/Logout/Logout')) },
  { path: '/apply', component: lazy(() => import('@src/routes/Apply-console/apply-iframe')) },
  { path: '/apply-carbon', component: lazy(() => import('@src/routes/Apply-console/apply-carbonx-iframe')) },
  { path: '/pdf2md', component: lazy(() => import('@src/routes/Pdf2md/Pdf2md')) },

  // 特殊资源 seeclimateweek2024
  { path: '/@seeclimateweek2024', component: lazy(() => import('@src/routes/SeeClimateWeek2024/SeeClimateWeek2024')) },
  // 如果用户使用了自定义路由@开头，导致没有匹配到上面任意路由，就会进入此路由
  {
    path: '/',
    component: lazy(() => import('@src/components/layout/ContentLayout')),
    children: [{ path: '/:id', component: lazy(() => import('@src/routes/CustomResOrTeam/CustomResOrTeam')) }],
  },
];

const lang: 'zh' | 'en' = lg.getLang();
const initCarbonLink = () => {
  replaceUrl();
  // await wxShareJsLoad();
  document.documentElement.lang = lang;
  const carbon = isCarbon();
  const baseName = lang === 'zh' && !carbon ? '/zh' : '';
  app.routes(routes, ILayout as any, baseName);

  // 初始化国际化词条配置
  import('../i18n/translation/en').then((en) => {
    init({ en: en.translation }, () => {
      I18NInstance.changeLanguage(lang);
    });
  });
};
// 立即执行
initCarbonLink();

// 路由判断
function replaceUrl() {
  // 动态添加路由
  const { pathname, search } = window.location;
  if (pathname.startsWith('/zh/zh/')) {
    const pathname = removeBasenameFromPathname();
    location.href = `${window.location.origin}${pathname}${search}`;
  }
  const carbon = isCarbon();
  if (pathname.startsWith('/zh') && carbon) {
    const pathname = removeBasenameFromPathname();
    location.href = `${window.location.origin}${pathname}${search}`;
  }
  if (!pathname.startsWith('/zh') && lg.isChinese && !carbon) {
    console.log('window.location.pathname', window.location.pathname);
    // 生成新的URL
    const newPathname = `/zh${window.location.pathname === '/' ? '' : window.location.pathname}`;
    // 仅修改浏览器地址栏URL，不重新刷新页面
    window.history.replaceState(null, '', `${window.location.origin}${newPathname}${window.location.search}`);
  }
}
