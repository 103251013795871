import logoPng from '@src/images/logo/tan-live-logo.svg';
import logoEn from '@src/images/logo/logo-en.png';
import logoEnWhite from '@src/images/logo/logo-en-white.png';
import logoWhitePng from '@src/images/logo/logoWhite.svg';
import logoGreyPng from '@src/images/logo/logo-grey.svg';
import logoGreyEnPng from '@src/images/logo/logo-grey-en.svg';
import { CookieUtil } from './cookie';
const LangZh: Lang = 'zh';
const LangEn: Lang = 'en';

export type Lang = 'zh' | 'en';
export const I18N_COOKIE_NAME = 'i18n_lang';

// 老数据兼容
(() => {
  const ls = CookieUtil.getCookie(I18N_COOKIE_NAME);
  try {
    JSON.parse(ls);
  } catch (error) {
    CookieUtil.deleteCookie(I18N_COOKIE_NAME);
  }
})();

// 系统语言判断
const isSystemChinese = () => {
  const list = ['zh-CN', 'zh-TW', 'zh'];
  const systemLan = navigator.language || (navigator as any).userLanguage;
  if (list.includes(systemLan)) {
    return true;
  }
  return false;
};

const setLangSwitch = (val: boolean) => {
  let ls = CookieUtil.getCookie(I18N_COOKIE_NAME);
  if (!ls) {
    ls = `{"sw": ${true}}`;
  }
  try {
    const json = JSON.parse(ls);
    json.sw = val;
    CookieUtil.setCookie(I18N_COOKIE_NAME, JSON.stringify(json));
  } catch (error) {
    CookieUtil.deleteCookie(I18N_COOKIE_NAME);
    setLangSwitch(val);
  }
};

const setLangFilter = (val: boolean) => {
  let ls = CookieUtil.getCookie(I18N_COOKIE_NAME);
  if (!ls) {
    ls = `{"f": ${false}}`;
  }
  try {
    const json = JSON.parse(ls);
    json.f = val;
    CookieUtil.setCookie(I18N_COOKIE_NAME, JSON.stringify(json));
  } catch (error) {
    CookieUtil.deleteCookie(I18N_COOKIE_NAME);
    setLangFilter(val);
  }
};

const getLangFilter = (): boolean => {
  let ls = CookieUtil.getCookie(I18N_COOKIE_NAME);
  if (!ls) {
    ls = `{"f": ${false}}`;
  }
  try {
    const langstore = JSON.parse(ls);
    return !!langstore.f;
  } catch (error) {
    CookieUtil.deleteCookie(I18N_COOKIE_NAME);
    return getLangFilter();
  }
};


const getLangSwitch = (): boolean => {
  let ls = CookieUtil.getCookie(I18N_COOKIE_NAME);
  if (!ls) {
    ls = `{"sw": ${true}}`;
  }
  try {
    const langstore = JSON.parse(ls);
    return !!langstore.sw;
  } catch (error) {
    CookieUtil.deleteCookie(I18N_COOKIE_NAME);
    return getLangSwitch();
  }
};


// UGC查看语言判断
const setLang = (val: Lang) => {
  let ls = CookieUtil.getCookie(I18N_COOKIE_NAME);
  if (!ls) {
    ls = `{"sw": ${true}}`;
  }
  try {
    const json = JSON.parse(ls);
    json.lang = val;
    CookieUtil.setCookie(I18N_COOKIE_NAME, JSON.stringify(json));
  } catch (error) {
    CookieUtil.deleteCookie(I18N_COOKIE_NAME);
    setLang(val);
  }

};

const urlIncludeZh = () => {
  const { pathname } = window.location;
  return pathname.startsWith('/zh') ? true : null;
};

const getStorageLang = () => {
  const ls = CookieUtil.getCookie(I18N_COOKIE_NAME);
  if (!ls) {
    return;
  }
  if (ls) {
    try {
      const store = JSON.parse(ls);
      return store;
    } catch (error) {
      CookieUtil.deleteCookie(I18N_COOKIE_NAME);
      return;
    }
  }
};

const getLang = (): Lang => {
  const includeZh = urlIncludeZh();
  const ls = getStorageLang();
  if (includeZh && ls?.lang != 'zh') {
    setLang('zh');
    return LangZh;
  }
  if (ls?.lang) {
    return ls?.lang;
  }
  return isSystemChinese() ? LangZh : LangEn;
};

// is
const isChinese = (() => getLang() === LangZh)();
const isEnglish = (() => getLang() === LangEn)();

const logo = (() => {
  if (isChinese) {
    return logoPng;
  }
  return logoEn;
})();

const logoWhite = (() => {
  if (isChinese) {
    return logoWhitePng;
  }
  return logoEnWhite;
})();

const logoGrey = (() => {
  if (isChinese) {
    return logoGreyPng;
  }
  return logoGreyEnPng;
})();

export const lg = {
  isChinese,
  isEnglish,
  getLang,
  setLang,
  LangZh,
  LangEn,
  isSystemChinese,
  logo,
  logoWhite,
  logoGrey,
  setLangSwitch,
  getLangSwitch,
  setLangFilter,
  getLangFilter,
};
