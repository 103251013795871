import { OneTrustCheck } from './oneTrust';


window.tanStorage = {};

((win) => {
  const nativeLocalStorage = win.localStorage;
  const nativeSessionStorage = win.sessionStorage;
  class NewLocalStorage {
    setItem(key, value) {
      if (OneTrustCheck.isCookiePrevent()) {
        win.tanStorage[key] = value;
      } else {
        nativeLocalStorage.setItem(key, value);
      }
    }

    getItem(key) {
      if (OneTrustCheck.isCookiePrevent()) {
        return win.tanStorage[key];
      }
      return nativeLocalStorage.getItem(key);
    }

    removeItem(key) {
      if (OneTrustCheck.isCookiePrevent()) {
        delete win.tanStorage[key];
      } else {
        nativeLocalStorage.removeItem(key);
      }
    }
    clear() {
      if (OneTrustCheck.isCookiePrevent()) {
        win.tanStorage = {};
      } else {
        nativeLocalStorage.clear();
      }
    }
  }

  class NewSessionStorage {
    setItem(key, value) {
      nativeSessionStorage.setItem(key, value);
    }

    getItem(key) {
      return nativeSessionStorage.getItem(key);
    }
    removeItem(key) {
      nativeSessionStorage.removeItem(key);
    }
    clear() {
      nativeSessionStorage.clear();
    }
  }

  const ls = new NewLocalStorage();
  const ss = new NewSessionStorage();

  Object.defineProperty(win, 'localStorage', {
    value: ls,
    writable: true,
  });
  Object.defineProperty(win, 'sessionStorage', {
    value: ss,
    writable: true,
  });
})(window);

// const _setItem = Storage.prototype.setItem;
// const _getItem = Storage.prototype.getItem;

// Storage.prototype.setItem = function (key, value) {
//   if (this === window.localStorage) {
//     // do what you want if setItem is called on localStorage
//   } else {
//     // fallback to default action
//     _setItem.apply(this, arguments);
//   }
// };

// Storage.prototype.getItem = function (key) {
//   // if (this === window.localStorage) {
//   //   // do what you want if setItem is called on localStorage
//   //   return localStorage.getItem(key);
//   // }
//   // fallback to default action
//   _getItem.apply(this, arguments);
// };
